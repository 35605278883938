<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12>
            <v-card-title class="elevation-0">
              <span class="itemHeading">Employee Details </span>
              <v-spacer></v-spacer>
              <v-flex
                xs12
                sm6
                md3
                text-right
                v-if="position == '62a2de91ecd959516cd3b0e6'"
              >
                <v-btn
                  color="#005f32"
                  rounded
                  outlined
                  @click="changePasswordDialog = true"
                  ><span style="font-family: kumbhRegular"
                    >Change Password</span
                  ></v-btn
                >
              </v-flex>
            </v-card-title>
          </v-flex>
          <!-- <v-flex xs2>
          
              <v-btn
                color="#005f32"
                style="font-family: kumbhMedium"
                large
                title="Edit"
                block
                icon
                @click="editcat(item)"
              >
                <v-icon> mdi-circle-edit-outline </v-icon>
                <span>Edit</span>
              </v-btn>
      
          </v-flex> -->
        </v-layout>

        <v-layout wrap justify-start v-if="data">
          <v-flex xs12 align-self-center pa-4>
            <v-layout wrap>
              <v-flex xs12 align-self-center>
                <v-card color="#F5F5DC" tile>
                  <v-layout wrap pa-2 justify-start v-if="data">
                    <v-flex xs12 sm4 md3 align-self-start text-left pa-2>
                      <template v-if="data.photo">
                        <v-avatar size="180" class="rounded-lg">
                          <v-img contain :src="mediaURL + data.photo" class="">
                          </v-img>
                        </v-avatar>
                      </template>
                      <template v-else>
                        <v-avatar size="180" class="rounded-lg" color="#f5f5f5">
                          <v-img
                            contain
                            class="rounded-lg"
                            :src="require('./../../assets/images/nopic.jpg')"
                          ></v-img>
                        </v-avatar>
                      </template>
                    </v-flex>

                    <v-flex xs12 sm8 md9 align-self-start text-left pa-2>
                      <v-layout wrap>
                        <v-flex xs9 align-self-center text-left pa-1>
                          <span
                            style="font-size: 22px"
                            class="kumbhMedium"
                            v-if="data.employeeId"
                          >
                            {{ data.employeeId }}
                          </span>
                          <span
                            style="font-size: 22px"
                            class="kumbhMedium"
                            v-else
                          >
                            NA
                          </span>
                        </v-flex>
                        <v-flex xs3 text-right>
                          <v-dialog
                            :retain-focus="false"
                            v-model="AwardDialog"
                            persistent
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                            scrollable
                            max-width="40%"
                            :key="AwardDialog"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn text v-bind="attrs" v-on="on">
                                <v-icon color="#FFB300">mdi-seal</v-icon>
                                <span style="color: green">Acheivements</span>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs9
                                  pa-4
                                  text-left
                                  style="background: #005f32 !important"
                                >
                                  <span
                                    class="kumbhBold"
                                    style="color: #ffffff; font-size: 20px"
                                  >
                                    <v-icon color="#FFB300">mdi-seal</v-icon
                                    >Acheivements History</span
                                  >
                                </v-flex>
                                <v-flex
                                  xs3
                                  pa-4
                                  text-right
                                  style="background: #005f32 !important"
                                >
                                  <v-btn
                                    color="white"
                                    outlined
                                    @click="
                                      (addAwardDialog = true),
                                        getYear1(),
                                        getAwardList()
                                    "
                                  >
                                    Add New
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap fill-height>
                                <v-flex xs12 pt-1 2 v-if="MyAwards.length > 0">
                                  <v-card v-if="MyAwards.length > 0">
                                    <v-simple-table>
                                      <template v-slot:default>
                                        <thead>
                                          <tr>
                                            <th class="text-left">No.</th>
                                            <th class="text-left">Year</th>
                                            <th class="text-left">Award</th>
                                            <th class="text-left">Edit</th>
                                            <!-- <th class="text-left">View</th> -->
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(item, i) in MyAwards"
                                            :key="i"
                                          >
                                            <td class="text-left">
                                              {{ i + 1 }}
                                              <!-- <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span> -->
                                            </td>
                                            <td class="text-left">
                                              {{ item.year }}
                                            </td>
                                            <td class="text-left">
                                              {{ item.achievementId.name }}
                                            </td>
                                            <td class="text-left">
                                              <v-icon
                                                small
                                                @click="
                                                  (editAwardDialog = true),
                                                    (curid = item._id),
                                                    (curyear = item.year),
                                                    (curaward =
                                                      item.achievementId),
                                                    getYear1(),
                                                    getAwardList()
                                                "
                                                >mdi-pencil</v-icon
                                              >
                                            </td>
                                          </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-card>
                                </v-flex>
                                <v-flex xs12 pt-12 v-else text-center>
                                  <span class="itemHeading"
                                    >No Acheivement's Found</span
                                  >
                                </v-flex>
                              </v-layout>

                              <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn
                                  color="error"
                                  tile
                                  outlined
                                  @click="AwardDialog = false"
                                >
                                  Close
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                        <v-flex xs12 align-self-center text-left pa-1>
                          <span
                            style="font-size: 22px"
                            class="kumbhMedium"
                            v-if="data.name"
                          >
                            {{ data.name }}
                          </span>
                          <span
                            style="font-size: 22px"
                            class="kumbhMedium"
                            v-else
                          >
                            NA
                          </span>
                        </v-flex>
                        <v-flex xs12 sm6 align-self-center text-left pa-1>
                          <span
                            style="font-size: 18px"
                            class="kumbhMedium"
                            v-if="data.employeeStatus"
                          >
                            Emp. Status : {{ data.employeeStatus }}
                          </span>
                          <span
                            style="font-size: 18px"
                            class="kumbhMedium"
                            v-else
                          >
                            NA
                          </span>
                        </v-flex>
                        <v-flex xs12 sm6 align-self-center text-left pa-1>
                          <span
                            style="font-size: 18px"
                            class="kumbhMedium"
                            v-if="data.dateofjoining"
                          >
                            Date of joining :
                            {{ data.dateofjoining.slice(0, 10) }}
                          </span>
                          <span
                            style="font-size: 18px"
                            class="kumbhMedium"
                            v-else
                          >
                            Date of joining : NA
                          </span>
                        </v-flex>

                        <v-flex xs12 align-self-center text-left pa-1 pt-4>
                          <v-layout wrap justify-start>
                            <v-flex xs12 align-self-center text-left pl-1>
                              <span style="font-size: 22px" class="kumbhMedium">
                                Designation
                              </span>
                            </v-flex>

                            <v-flex
                              xs12
                              sm4
                              md3
                              align-self-center
                              pa-1
                              text-left
                              v-for="(item, i) in data.positionid"
                              :key="i"
                            >
                              <!-- <v-btn
                                dark
                                block
                                depressed
                                color="#000"
                                class="rounded-lg"
                                style="opacity: 0.8 !important"
                              >
                                <span class="kumbhMedium">
                                  {{ item.name }}
                                </span>
                              </v-btn> -->

                              <v-tooltip
                                v-model="item.show"
                                top
                                color="transparent"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    dark
                                    block
                                    depressed
                                    @click="item.show = !item.show"
                                    color="#000"
                                    class="rounded-lg"
                                    style="opacity: 0.8 !important"
                                  >
                                    <span class="kumbhMedium">
                                      {{ item.name }}
                                    </span>
                                  </v-btn>
                                </template>
                                <v-card
                                  tile
                                  color="#000"
                                  style="color: #ffffff"
                                >
                                  <v-layout wrap pa-4 v-if="item.department">
                                    <v-flex xs12 align-self-center pa-1 pb-2>
                                      <span
                                        class="kumbhMedium"
                                        style="border-bottom: 1px solid #ccc"
                                      >
                                        Department
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.department"
                                    >
                                      <span
                                        class="kumbhMedium"
                                        v-if="item.department.deptName"
                                      >
                                        {{ item.department.deptName }}
                                      </span>
                                    </v-flex>
                                  </v-layout>

                                  <v-layout wrap pa-2 v-if="item.project">
                                    <v-flex xs12 align-self-center pa-1 pb-2>
                                      <span
                                        class="kumbhMedium"
                                        style="border-bottom: 1px solid #ccc"
                                      >
                                        Project
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.project"
                                    >
                                      <span
                                        class="kumbhMedium"
                                        v-if="item.project.projectName"
                                      >
                                        Project : {{ item.project.projectName }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.project.department"
                                    >
                                      <span
                                        class="kumbhMedium"
                                        v-if="item.project.department.deptName"
                                      >
                                        Department:
                                        {{ item.project.department.deptName }}
                                      </span>
                                    </v-flex>
                                  </v-layout>
                                </v-card>
                              </v-tooltip>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-layout wrap justify-start>
                <v-flex xs12 align-self-center pa-4>
                  <v-simple-table fixed-header class="elevation-1 #F5F5DC">
                    <template v-slot:default>
                      <thead>
                        <tr class="kumbhEBold">
                          <th class="text-left">Department</th>
                          <th class="text-left">Division</th>
                          <th class="text-left">Project</th>
                        </tr>
                      </thead>
                      <tbody class="kumbhRegular">
                        <tr v-for="(item, i) in projects" :key="i">
                          <td class="text-left">
                            <span>
                              {{ item.departmentId.deptName }}
                            </span>
                          </td>
                          <td class="text-left">
                            <span>
                              {{ item.division.divisiontName }}
                            </span>
                          </td>

                          <td class="text-left">
                            <span>
                              {{ item.ProjectId.projectName }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-layout>
              <v-flex xs12 align-self-center pt-4>
                <v-layout wrap class="kumbhRegular" style="font-size: 17px">
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Date of Birth :
                      <span class="kumbhMedium" v-if="data.dob">
                        {{ data.dob.slice(0, 10) }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Age :
                      <span class="kumbhMedium" v-if="data.age">
                        {{ data.age }}</span
                      >
                    </span>
                  </v-flex>

                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Gender :
                      <span class="kumbhMedium" v-if="data.gender">
                        {{ data.gender }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Email :
                      <span class="kumbhMedium" v-if="data.email">
                        {{ data.email }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Phone :
                      <span class="kumbhMedium" v-if="data.phonenumber">
                        {{ data.phonenumber }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Address :
                      <span class="kumbhMedium" v-if="data.address">
                        {{ data.address }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Salary(INR) :
                      <span class="kumbhMedium" v-if="data.salary">
                        {{ data.salary.salary }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Grade :
                      <span class="kumbhMedium" v-if="data.grade">
                        {{ data.grade }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Role :
                      <span class="kumbhMedium" v-if="data.role">
                        {{ data.role.roles }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Date of Probation Completion :
                      <span
                        class="kumbhMedium"
                        v-if="data.dateofProbationCompletion"
                      >
                        {{ data.dateofProbationCompletion.slice(0, 10) }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Education :
                      <span class="kumbhMedium" v-if="data.education">
                        {{ data.education }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Office Address :
                      <span class="kumbhMedium" v-if="data.officeAddress">
                        {{ data.officeAddress }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Official Email :
                      <span class="kumbhMedium" v-if="data.officialEmail">
                        {{ data.officialEmail }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Location :
                      <span class="kumbhMedium" v-if="data.location">
                        {{ data.location }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Monthly CTC :
                      <span class="kumbhMedium" v-if="data.monthlyCTC">
                        {{ data.monthlyCTC }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Yearly Package :
                      <span class="kumbhMedium" v-if="data.yearlyPackage">
                        {{ data.yearlyPackage }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >PMR Scores :
                      <span class="kumbhMedium" v-if="data.pmrscoreId">
                        {{ data.pmrscoreId.rating }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Show Cause :
                      <span class="kumbhMedium" v-if="data.isShowcause">
                        Yes</span
                      >
                      <span class="kumbhMedium" v-else> No</span>
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Exit Date :
                      <span class="kumbhMedium" v-if="data.exitDate">
                        {{ data.exitDate.slice(0, 10) }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Exit Type :
                      <span class="kumbhMedium" v-if="data.exitType">
                        {{ data.exitType }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Exit Reason :
                      <span class="kumbhMedium" v-if="data.exitReason">
                        {{ data.exitReason }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Eligibility to Rehire :
                      <span class="kumbhMedium" v-if="data.eligibilitytoRehire">
                        {{ data.eligibilitytoRehire }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Promotion:
                      <span class="kumbhMedium" v-if="data.promotion">
                        {{ data.promotion }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Others:
                      <span class="kumbhMedium" v-if="data.others">
                        {{ data.others }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 align-self-center py-2>
                    <v-spacer></v-spacer>
                  </v-flex>

                  <v-flex xs12 sm12 align-self-center text-left pa-1>
                    <span
                      >Administrative Reporting Manager :
                      <span  v-for="(admin, index) in data.administrativeReportingManagersIds"  :key="index"
                        class="kumbhMedium" 
                       
                      > 
                     
                      {{ admin.name }} <span v-if="index < data.administrativeReportingManagersIds.length-1">,</span> </span
                      >
                      
                    </span>
                  </v-flex>
                  <v-flex xs12 sm12 align-self-center text-left pa-1>
                    <span
                      >Technical Reporting Manager :
                      <span
                        class="kumbhMedium"
                        v-for="(tech, index) in data.technicalReportingManagersIds"  :key="index"
                      >
                      {{ tech.name }} <span v-if="index < data.administrativeReportingManagersIds.length-1">,</span> </span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm12 align-self-center text-left pa-1>
                    <span
                      >Principal Invigilator :
                      <span
                        class="kumbhMedium"
                        style="font-size: 19px"
                        v-if="data.principalInvigilator"
                      >
                        {{ data.principalInvigilator.name }}</span
                      >
                    </span>
                  </v-flex>

                  <!---------------------------SALARY--------------------------------------->
                  <v-flex xl3 lg2 md3 pb-2 pr-2 sm3 xs12>
                    <v-dialog
                      :retain-focus="false"
                      v-model="SalaryListDialog"
                      transition="dialog-bottom-transition"
                      max-width="600px"
                      :key="SalaryListDialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="font-family: kumbhBold"
                          dark
                          block
                          class="rounded-xl"
                          color="#005f32"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Salary
                        </v-btn>
                      </template>
                      <v-card>
                        <!--  -->
                        <v-layout wrap>
                          <v-flex
                            xs9
                            pa-4
                            text-left
                            style="background: #005f32 !important"
                          >
                            <span
                              class="kumbhBold"
                              style="color: #ffffff; font-size: 20px"
                              >Add Salary</span
                            >
                          </v-flex>
                          <v-flex
                            xs3
                            pa-4
                            text-right
                            style="background: #005f32 !important"
                          >
                            <v-btn
                              color="white"
                              outlined
                              @click="addSalaryDialog = true"
                            >
                              Add Salary
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap fill-height>
                          <v-flex xs12 pa-2 v-if="salaryList">
                            <v-card v-if="salaryList.length > 0">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">No.</th>
                                      <th class="text-left">Financial Year</th>
                                      <th class="text-left">Salary(INR)</th>
                                      <th class="text-left">Edit</th>
                                      <!-- <th class="text-left">View</th> -->
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, i) in salaryList"
                                      :key="i"
                                    >
                                      <td class="text-left">
                                        {{ i + 1 }}
                                        <!-- <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span> -->
                                      </td>
                                      <td class="text-left">
                                        {{ item.fyear }}
                                      </td>
                                      <td class="text-left">
                                        {{ item.salary }}
                                      </td>
                                      <td class="text-left">
                                        <v-icon
                                          small
                                          @click="
                                            (editSalaryDialog = true),
                                              (curitem2 = item)
                                          "
                                          >mdi-pencil</v-icon
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </v-flex>
                        </v-layout>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color="error"
                            tile
                            outlined
                            @click="SalaryListDialog = false"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                  <!---------------------------GRADE---------------------------------------------->
                  <v-flex xl3 lg2 md3 pb-2 pl-2 sm3 xs12>
                    <v-dialog
                      :retain-focus="false"
                      v-model="gradeListDialog"
                      transition="dialog-bottom-transition"
                      scrollable
                      max-width="600px"
                      :key="gradeListDialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="font-family: kumbhBold"
                          dark
                          block
                          class="rounded-xl"
                          color="#005f32"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Grade
                        </v-btn>
                      </template>
                      <v-card>
                        <v-layout wrap>
                          <v-flex
                            xs9
                            pa-4
                            text-left
                            style="background: #005f32 !important"
                          >
                            <span
                              class="kumbhBold"
                              style="color: #ffffff; font-size: 20px"
                              >Add Grade</span
                            >
                          </v-flex>
                          <v-flex
                            xs3
                            pa-4
                            text-right
                            style="background: #005f32 !important"
                          >
                            <v-btn
                              color="white"
                              outlined
                              @click="addGradeDialog = true"
                            >
                              Add Grade
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap fill-height>
                          <v-flex xs12 pa-2 v-if="gradeList">
                            <v-card v-if="gradeList.length > 0">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">No.</th>
                                      <th class="text-left">Financial Year</th>
                                      <th class="text-left">Grade</th>
                                      <th class="text-left">Edit</th>
                                      <!-- <th class="text-left">View</th> -->
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item, i) in gradeList" :key="i">
                                      <td class="text-left">
                                        {{ i + 1 }}
                                        <!-- <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span> -->
                                      </td>
                                      <td class="text-left">
                                        {{ item.fyear }}
                                      </td>
                                      <td class="text-left">
                                        {{ item.grade }}
                                      </td>
                                      <td class="text-left">
                                        <v-icon
                                          small
                                          @click="
                                            (editGradeDialog = true),
                                              (curitem = item)
                                          "
                                          >mdi-pencil</v-icon
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                            <v-card flat v-else>
                              <v-layout wrap>
                                <v-flex xs12 text-center>
                                  <span class="itemHeading"
                                    >No grade's found</span
                                  >
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                          <!-- <v-flex
                              xs12
                              align-self-center
                              text-left
                              pa-2
                              px-5
                              pt-8
                            >
                              <v-select
                                v-model="Gyear"
                                :items="yearList"
                                item-text="name"
                                item-value="_id"
                                outlined
                                dense
                                :rules="[rules.required]"
                                required
                                hide-details
                                label="Choose Year"
                              >
                              </v-select>
                            </v-flex>
                            <v-flex xs12 align-self-center text-left px-5 pa-2>
                              <v-text-field
                                v-model="grade"
                                label="Grade"
                                hide-details
                                :rules="[rules.required]"
                                required
                                outlined
                                type="text"
                                dense
                              ></v-text-field>
                            </v-flex> -->
                        </v-layout>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color="error"
                            tile
                            outlined
                            @click="gradeListDialogclose()"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                  <!---------------------------MANAGER---------------------------------------------->
                  <v-flex xl3 lg2 md3 pb-2 pl-2 sm3 xs12>
                    <v-dialog
                      :retain-focus="false"
                      v-model="MngrListDialog"
                      persistent
                      hide-overlay
                      transition="dialog-bottom-transition"
                      scrollable
                      max-width="600px"
                      :key="MngrListDialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="font-family: kumbhBold"
                          dark
                          block
                          class="rounded-xl"
                          color="#005f32"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Manager
                        </v-btn>
                      </template>
                      <v-card>
                        <v-layout wrap>
                          <v-flex
                            xs9
                            pa-4
                            text-left
                            style="background: #005f32 !important"
                          >
                            <span
                              class="kumbhBold"
                              style="color: #ffffff; font-size: 20px"
                              >Manager List</span
                            ></v-flex
                          >
                          <v-flex
                            xs3
                            pa-4
                            text-right
                            style="background: #005f32 !important"
                          >
                            <v-btn
                              color="white"
                              outlined
                              @click="addMngrDialog = !addMngrDialog"
                            >
                              Add Manager
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap fill-height>
                          <v-flex xs12 pa-2 v-if="managerList">
                            <v-card v-if="managerList.length > 0">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">No.</th>
                                      <th class="text-left">Financial Year</th>
                                      <th class="text-left">
                                        Administrative Reporting Manager
                                      </th>
                                      <th class="text-left">
                                        Technical Reporting Manager
                                      </th>
                                      <th class="text-left">
                                        Principal Invigilator
                                      </th>
                                      <!-- <th class="text-left">View</th> -->
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, i) in managerList"
                                      :key="i"
                                    >
                                      <td class="text-left">
                                        {{ i + 1 }}
                                        <!-- <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span> -->
                                      </td>
                                      <td class="text-left">
                                        {{ item.fyear }}
                                      </td>
                                      <td class="text-left">
                                        {{ item.adminReportManager.name }}
                                      </td>
                                      <td class="text-left">
                                        {{ item.techReportManager.name }}
                                      </td>
                                      <td class="text-left">
                                        {{ item.PI.name }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </v-flex>
                        </v-layout>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color="error"
                            tile
                            outlined
                            @click="MngrListDialog = false"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                  <!---------------------------ASSIGN---------------------------------------------->

                  <v-flex xl3 lg2 md3 pl-2 pr-2>
                    <v-btn
                      @click="$router.push('/Assign?id=' + employeeId)"
                      style="font-family: kumbhBold"
                      dark
                      block
                      class="rounded-xl"
                      color="#005f32"
                    >
                      ASSIGN
                    </v-btn></v-flex
                  >

                  <v-flex xl3 lg2 md3 pl-2 pr-2>
                    <v-btn
                      @click="$router.push('/SubTeam2?id=' + employeeId)"
                      style="font-family: kumbhBold"
                      dark
                      block
                      class="rounded-xl"
                      color="#005f32"
                    >
                      SUBTEAM
                    </v-btn>
                  </v-flex>
                  <v-flex xl3 lg2 md3 pl-2 pr-2>
                    <v-btn
                    @click="
                      $router.push(
                        '/Attendance/teamMemberAttendance?id=' +
                          $route.query.id + '&name=' + empname + '&empid=' + empid
                      )
                    "
                      style="font-family: kumbhBold"
                      dark
                      block
                      class="rounded-xl"
                      color="#005f32"
                    >
                      Attendance
                    </v-btn>
                  </v-flex>
                  <!---------------------------SHOW CAUSE---------------------------------------------->
                  <v-flex xl3 lg2 md3 pb-2 pr-2 sm3 xs12>
                    <v-dialog
                      :retain-focus="false"
                      v-model="showDialog"
                      transition="dialog-bottom-transition"
                      max-width="600px"
                      :key="showDialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="font-family: kumbhBold"
                          dark
                          block
                          class="rounded-xl"
                          color="#005f32"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Show Cause
                        </v-btn>
                      </template>
                      <v-card>
                        <!--  -->
                        <v-layout wrap>
                          <v-flex
                            xs12
                            pa-4
                            text-left
                            style="background: #005f32 !important"
                          >
                            <span
                              class="kumbhBold"
                              style="color: #ffffff; font-size: 20px"
                              >Add Show Cause</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-layout wrap fill-height>
                          <v-flex xs12 pa-2>
                            <v-layout wrap>
                              <v-flex
                                xs12
                                sm12
                                align-self-center
                                text-left
                                pa-2
                                px-4
                              >
                                <v-textarea
                                  v-model="notice"
                                  label="Show cause notice/warning"
                                  required
                                  hide-details
                                  outlined
                                  dense
                                ></v-textarea>
                              </v-flex>
                              <v-flex
                                xs12
                                sm12
                                align-self-center
                                text-left
                                pa-2
                                px-4
                              >
                                <v-text-field
                                  v-model="email"
                                  label="Email"
                                  hide-details
                                  required
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-flex>
                              <v-flex
                                xs12
                                sm12
                                align-self-center
                                text-left
                                pa-2
                                px-4
                              >
                                <v-text-field
                                  prepend-inner-icon="mdi-file-upload"
                                  label="Letter/Transfer letter"
                                  hide-details
                                  required
                                  outlined
                                  v-model="file.name"
                                  dense
                                  @click="$refs.files.click()"
                                ></v-text-field>
                                <!-- <v-file-input
                                  label="Letter/Transfer letter"
                                  outlined
                                  dense
                                  @change="uploadFile($event)"
                                ></v-file-input> -->
                                <input
                                  v-show="false"
                                  id="file"
                                  ref="files"
                                  type="file"
                                  @change="uploadFile($event)"
                                />
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color="error"
                            tile
                            outlined
                            @click="showDialog = false"
                          >
                            Close
                          </v-btn>
                          <v-btn
                            color="green"
                            tile
                            outlined
                            @click="addShowCause()"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                  <!---------------------------EXPERIENCE--------------------------------------->
                  <v-flex xl3 lg2 md3 pb-2 pr-2 sm3 xs12>
                    <v-dialog
                      :retain-focus="false"
                      v-model="experienceDialog"
                      transition="dialog-bottom-transition"
                      max-width="800px"
                      :key="experienceDialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="font-family: kumbhBold"
                          dark
                          block
                          class="rounded-xl"
                          color="#005f32"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Experience
                        </v-btn>
                      </template>
                      <v-card>
                        <!--  -->
                        <v-layout wrap>
                          <v-flex
                            xs8
                            pa-4
                            text-left
                            style="background: #005f32 !important"
                          >
                            <span
                              class="kumbhBold"
                              style="color: #ffffff; font-size: 20px"
                              >Add Experience</span
                            >
                          </v-flex>
                          <v-flex
                            xs4
                            pa-4
                            text-right
                            style="background: #005f32 !important"
                          >
                            <v-btn
                              color="white"
                              outlined
                              @click="addExperienceDialog = true"
                            >
                              Add Experience
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap fill-height>
                          <v-flex xs12 pa-2 v-if="experienceArray">
                            <v-card v-if="experienceArray.length > 0">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">No.</th>
                                      <th class="text-left">Experience Type</th>
                                      <th class="text-left">Title</th>
                                      <th class="text-left">Duration (In Months)</th>
                                      <th class="text-left">Edit</th>
                                      <!-- <th class="text-left">View</th> -->
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, i) in experienceArray"
                                      :key="i"
                                    >
                                      <td class="text-left">
                                        {{ i + 1 }}
                                        <!-- <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span> -->
                                      </td>
                                      <td class="text-left">
                                        {{ item.type }}
                                      </td>
                                      <td class="text-left">
                                        {{ item.title }}
                                      </td>
                                      <td class="text-left">
                                        {{ item.duration }}
                                      </td>
                                      <td class="text-left">
                                        <v-icon
                                          small
                                          @click="
                                            (editExperienceDialog = true),
                                              (curitem2 = item)
                                          "
                                          >mdi-pencil</v-icon
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </v-flex>
                        </v-layout>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color="error"
                            tile
                            outlined
                            @click="experienceDialog = false"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-dialog v-model="addMngrDialog" max-width="500px">
          <v-card>
            <v-card-title class="pa-0">
              <v-layout wrap style="background: #005f32 !important">
                <v-flex text-left xs12 pa-4>
                  <span style="color: white !important">Add Manager</span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-form v-model="addmanager" ref="addmanager">
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2 px-5 pt-2>
                    <v-select
                      v-model="Mngryear"
                      :items="yearList"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Year"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-autocomplete
                      v-model="administrativeReportingManager"
                      :items="empList1"
                      item-text="name"
                      item-value="_id"
                      outlined
                      hide-details
                      label="Choose Administrative Reporting Manager"
                      dense
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-autocomplete
                      v-model="principalInvigilator"
                      :items="empList1"
                      item-text="name"
                      item-value="_id"
                      outlined
                      hide-details
                      label="Choose Principal Invigilator/Investigator"
                      dense
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-autocomplete
                      v-model="technicalReportingManager"
                      :items="empList1"
                      item-text="name"
                      item-value="_id"
                      outlined
                      hide-details
                      label="Choose Technical Reporting Manager"
                      dense
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" tile outlined @click="addMngrDialog = false">
                Close
              </v-btn>
              &nbsp;
              <v-btn
                color="#005f32"
                dark
                tile
                :disabled="!addmanager"
                @click="addManager()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="addGradeDialog" max-width="500px">
          <v-card>
            <v-card-title class="pa-0">
              <v-layout wrap style="background: #005f32 !important">
                <v-flex text-left xs12 pa-4>
                  <span style="color: white !important">Add Grade</span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-form v-model="addgrade" ref="addgrade">
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2 px-5 pt-8>
                    <v-select
                      v-model="Gyear"
                      :items="yearList"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Year"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-text-field
                      v-model="grade"
                      label="Grade"
                      hide-details
                      :rules="[rules.required]"
                      required
                      outlined
                      type="text"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error"
                tile
                outlined
                @click="addGradeDialog = false"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                color="#005f32"
                dark
                tile
                :disabled="!addgrade"
                @click="addGrade()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="editGradeDialog" max-width="500px">
          <v-card>
            <v-card-title class="pa-0">
              <v-layout wrap style="background: #005f32 !important">
                <v-flex text-left xs12 pa-4>
                  <span style="color: white !important">Edit Grade</span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-form v-model="editgrade" ref="editgrade">
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2 px-5 pt-8>
                    <v-select
                      v-model="curitem.fyear"
                      :items="yearList"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      disabled
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Year"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-text-field
                      v-model="curitem.grade"
                      label="Grade"
                      hide-details
                      :rules="[rules.required]"
                      required
                      outlined
                      type="text"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error"
                tile
                outlined
                @click="editGradeDialog = false"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                color="#005f32"
                dark
                tile
                :disabled="!editgrade"
                @click="editGrade()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="addSalaryDialog" max-width="500px">
          <v-card>
            <v-card-title class="pa-0">
              <v-layout wrap style="background: #005f32 !important">
                <v-flex text-left xs12 pa-4>
                  <span style="color: white !important">Add Salary</span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <!-- <v-form v-model="addcat" ref="addcat"> -->
              <v-form v-model="addsalary" ref="addsalary">
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2 px-5 pt-8>
                    <v-select
                      v-model="year"
                      :items="yearList"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Year"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-text-field
                      v-model="salary"
                      label="Salary"
                      hide-details
                      :rules="[rules.required]"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error"
                tile
                outlined
                @click="addSalaryDialog = false"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                color="#005f32"
                dark
                tile
                :disabled="!addsalary"
                @click="addSalary()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="editSalaryDialog" max-width="500px">
          <v-card>
            <v-card-title class="pa-0">
              <v-layout wrap style="background: #005f32 !important">
                <v-flex text-left xs12 pa-4>
                  <span style="color: white !important">Edit Salary</span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-form v-model="editsalary" ref="editsalary">
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2 px-5 pt-8>
                    <v-select
                      v-model="curitem2.fyear"
                      :items="yearList"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      disabled
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Year"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-text-field
                      v-model="curitem2.salary"
                      label="Grade"
                      hide-details
                      :rules="[rules.required]"
                      required
                      outlined
                      type="text"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error"
                tile
                outlined
                @click="editSalaryDialog = false"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                color="#005f32"
                dark
                tile
                :disabled="!editsalary"
                @click="editSalary()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="addAwardDialog" max-width="500px">
          <v-card>
            <v-card-title class="pa-0">
              <v-layout wrap style="background: #005f32 !important">
                <v-flex text-left xs12 pa-4>
                  <span style="color: white !important">Add Grade</span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-form v-model="addaward" ref="addaward">
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2 px-5 pt-8>
                    <v-select
                      v-model="Ayear"
                      :items="singleYear"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Year"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-select
                      v-model="award"
                      :items="AwardList"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Award"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error"
                tile
                outlined
                @click="addAwardDialog = false"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                color="#005f32"
                dark
                tile
                :disabled="!addaward"
                @click="addAward()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="editAwardDialog" max-width="500px">
          <v-card>
            <v-card-title class="pa-0">
              <v-layout wrap style="background: #005f32 !important">
                <v-flex text-left xs12 pa-4>
                  <span style="color: white !important">Edit Grade</span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-form v-model="editaward" ref="editaward">
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2 px-5 pt-8>
                    <v-select
                      v-model="curyear"
                      :items="singleYear"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Year"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-select
                      v-model="curaward"
                      :items="AwardList"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Award"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error"
                tile
                outlined
                @click="editAwardDialog = false"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                color="#005f32"
                dark
                tile
                :disabled="!editaward"
                @click="editAward()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="addExperienceDialog" max-width="500px">
          <v-card>
            <v-card-title class="pa-0">
              <v-layout wrap style="background: #005f32 !important">
                <v-flex text-left xs12 pa-4>
                  <span style="color: white !important">Add Salary</span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-form v-model="addexp" ref="addexp">
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2 px-5 pt-8>
                    <v-select
                      v-model="type"
                      :items="experienceTypes"
                      outlined
                      dense
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Type"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-text-field
                      v-model="title"
                      label="Title"
                      hide-details
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-text-field
                      v-model="duration"
                      label="Duration ( In Months )"
                      hide-details
                      :rules="[rules.required]"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error"
                tile
                outlined
                @click="addExperienceDialog = false"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                color="#005f32"
                dark
                tile
                :disabled="!addexp"
                @click="addExperience()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="editExperienceDialog" max-width="500px">
          <v-card>
            <v-card-title class="pa-0">
              <v-layout wrap style="background: #005f32 !important">
                <v-flex text-left xs12 pa-4>
                  <span style="color: white !important">Edit Experience</span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-form v-model="editexp" ref="editexp">
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2 px-5 pt-8>
                    <v-select
                      v-model="curitem2.type"
                      :items="experienceTypes"
                      outlined
                      dense
                      readonly
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Type"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-text-field
                      v-model="curitem2.title"
                      label="Title"
                      hide-details
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2>
                    <v-text-field
                      v-model="curitem2.duration"
                      label="Duration ( In Months )"
                      hide-details
                      :rules="[rules.required]"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error"
                tile
                outlined
                @click="editExperienceDialog = false"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                color="#005f32"
                dark
                tile
                :disabled="!editexp"
                @click="editExperience()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="changePasswordDialog"
          persistent
          :width="
            $vuetify.breakpoint.name == 'xs'
              ? '100vw'
              : $vuetify.breakpoint.name == 'sm'
              ? '50vw'
              : $vuetify.breakpoint.name == 'md' ||
                $vuetify.breakpoint.name == 'lg'
              ? '40vw'
              : '25vw'
          "
        >
          <v-card>
            <v-layout wrap justify-center pa-4>
              <v-flex xs12>
                <v-layout wrap justify-center py-4>
                  <v-flex xs12 lg10>
                    <v-layout wrap justify-start>
                      <!-- <v-flex xs12 text-left px-2>
                        <span class="itemValue">Current Password</span>
                        <v-text-field
                          ref="password"
                          color="#005f32"
                          placeholder="••••••••"
                          dense
                          v-model="oldPassword"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show1 ? 'text' : 'password'"
                          counter
                          @click:append="show1 = !show1"
                        ></v-text-field>
                      </v-flex> -->
                      <v-flex xs12 text-left px-2>
                        <span class="itemValue">New Password</span>
                        <v-text-field
                          ref="lastname"
                          color="#005f32"
                          placeholder="••••••••"
                          dense
                          v-model="password"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show2 ? 'text' : 'password'"
                          counter
                          @click:append="show2 = !show2"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 text-left px-2>
                        <span class="itemValue">Confirm New Password</span>
                        <v-text-field
                          ref="lastname"
                          color="#005f32"
                          placeholder="••••••••"
                          dense
                          v-model="confirmpassword"
                          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show3 ? 'text' : 'password'"
                          counter
                          @click:append="show3 = !show3"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center>
                  <v-flex xs12 sm6 xl3 pa-2>
                    <v-btn
                      block
                      tile
                      outlined
                      color="#FF1313"
                      light
                      :ripple="false"
                      depressed
                      @click="changePasswordDialog = false"
                      class="itemValue"
                    >
                      Cancel
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm6 xl3 pa-2>
                    <v-btn
                      block
                      tile
                      color="#005f32"
                      dark
                      :ripple="false"
                      depressed
                      @click="validateInput"
                      class="itemValue"
                    >
                      Change Password
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      emp: "",
      changePasswordDialog: false,
      password: null,
      oldPassword: null,
      confirmpassword: null,
      show1: false,
      show2: true,
      show3: false,
      addaward: false,
      addcat: false,
      addsalary: false,
      addexp: false,
      editexp: false,
      editsalary: false,
      addgrade: false,
      editgrade: false,
      addmanager: false,
      AwardDialog: false,
      addAwardDialog: false,
      employeeId: null,
      editAwardDialog: false,
      addSalaryDialog: false,
      SalaryListDialog: false,
      editSalaryDialog: false,
      addGradeDialog: false,
      gradeListDialog: false,
      editGradeDialog: false,
      addMngrDialog: false,
      MngrListDialog: false,
      editMngrDialog: false,
      showDialog: false,
      experienceDialog: false,
      addExperienceDialog: false,
      editExperienceDialog: false,
      experienceTypes: [],
      type: "",
      title: "",
      duration: "",
      experienceArray: [],
      curitem: [],
      curitem2: [],
      curid: null,
      curyear: null,
      curaward: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
      yearList: [],
      managerList: [],
      gradeList: [],
      salaryList: [],
      AwardList: [],
      year: null,
      salary: null,
      Gyear: null,
      grade: null,
      Mngryear: null,
      empList1: null,
      projects: [],
      empList2: null,
      empList3: null,
      administrativeReportingManager: null,
      technicalReportingManager: null,
      principalInvigilator: null,
      pageCount: 1,
      appLoading: false,
      currentPage: 1,
      data: [],
      singleYear: [],
      Ayear: null,
      award: null,
      MyAwards: [],
      editaward: false,
      notice: "",
      file: "",
      email: "",
      formData: new FormData(),
      empname: "",
      empid: "",
    };
  },
  computed: {
    position() {
      return localStorage.getItem("positionId");
    },
  },
  beforeMount() {
    this.getData();
    this.getyear();
    this.getsalary();
    this.getgrade();
    this.getUsers1();
    this.getMngr();
    this.getMyAwards();
    this.getExperienceType();
    this.getExperience();
  },
  mounted() {
    // this.employeeId = this.$route.query.id;
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/employee/details/view",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data;
          if(response.data.data){
          this.empname = response.data.data.name;

          this.empid = response.data.data.employeeId;
          }
          this.employeeId = response.data.data._id;
          this.projects = response.data.projects;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadFile(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        console.log("haii", file);
      }
    },
    addShowCause() {
      this.formData.append("showcauseEmail", this.email);
      this.formData.append("showcauseReason", this.notice);
      this.formData.append("id", this.$route.query.id);
      this.formData.append("showcauseNotice", this.file);
      axios({
        url: "/showcause/notice/add",
        method: "POST",
        data: this.formData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.showDialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getMyAwards() {
      this.appLoading = true;
      axios({
        url: "/employee/award/getlist",
        method: "GET",
        params: {
          employeeId: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.MyAwards = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    dialogclose() {
      this.name = null;
      this.addSalaryDialog = false;
    },
    gradeListDialogclose() {
      this.grade = null;
      this.gradeListDialog = false;
    },
    addSalary() {
      var data = {};
      data["fyear"] = this.year;
      data["salary"] = this.salary;
      data["employeeId"] = this.$route.query.id;
      axios({
        url: "/salary/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.salary = null;
            this.addSalaryDialog = false;
            this.getsalary();
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addGrade() {
      var data = {};
      data["fyear"] = this.Gyear;
      data["grade"] = this.grade;
      data["employeeId"] = this.$route.query.id;
      axios({
        url: "/grade/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.grade = null;
            this.gradeListDialog = false;
            this.addGradeDialog = false;
            this.getgrade();
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addManager() {
      var data = {};
      data["adminReportManager"] = this.administrativeReportingManager;
      data["techReportManager"] = this.technicalReportingManager;
      data["PI"] = this.principalInvigilator;
      data["fyear"] = this.Mngryear;
      data["employeeId"] = this.$route.query.id;
      axios({
        url: "/managers/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.administrativeReportingManager = null;
            this.technicalReportingManager = null;
            this.principalInvigilator = null;
            this.Mngryear = null;
            this.MngrListDialog = false;
            this.addMngrDialog = false;
            this.getMngr();
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSalary() {
      var data = {};
      data["fyear"] = this.curitem2.fyear;
      data["salary"] = this.curitem2.salary;
      data["id"] = this.curitem2._id;
      axios({
        url: "/salary/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.grade = null;
            this.SalaryListDialog = false;
            this.editSalaryDialog = false;
            this.getsalary();
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editGrade() {
      var data = {};
      data["fyear"] = this.curitem.fyear;
      data["grade"] = this.curitem.grade;
      data["id"] = this.curitem._id;
      axios({
        url: "/grade/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.grade = null;
            this.gradeListDialog = false;
            this.editGradeDialog = false;
            this.getgrade();
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editManager() {
      var data = {};
      data["adminReportManager"] = this.administrativeReportingManager;
      data["techReportManager"] = this.technicalReportingManager;
      data["PI"] = this.principalInvigilator;
      data["fyear"] = this.Mngryear;
      data["employeeId"] = this.$route.query.id;
      axios({
        url: "/managers/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.administrativeReportingManager = null;
            this.technicalReportingManager = null;
            this.principalInvigilator = null;
            this.Mngryear = null;
            this.MngrListDialog = false;
            this.addMngrDialog = false;
            this.getMngr();
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getExperience() {
      axios({
        method: "POST",
        url: "/employee/experience/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.experienceArray = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getExperienceType() {
      axios({
        method: "get",
        url: "/experience/type/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.experienceTypes = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    addExperience() {
      var data = {};
      data["type"] = this.type;
      data["title"] = this.title;
      data["duration"] = this.duration;
      data["id"] = this.$route.query.id;
      axios({
        url: "/employee/experience/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.title = null;
            this.duration = null;
            this.addExperienceDialog = false;
            this.getExperience();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editExperience() {
      var data = {};
      data["title"] = this.curitem2.title;
      data["duration"] = this.curitem2.duration;
      data["experienceId"] = this.curitem2._id;
      axios({
        url: "/employee/experience/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.editExperienceDialog = false;
            this.getExperience();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getyear() {
      axios({
        method: "get",
        url: "/financialyear/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.yearList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getsalary() {
      this.appLoading = true;
      axios({
        url: "/salary/getlist",
        method: "GET",
        params: {
          employeeId: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.salaryList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getgrade() {
      this.appLoading = true;
      axios({
        url: "/grade/getlist",
        method: "GET",
        params: {
          employeeId: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.gradeList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getUsers1() {
      this.appLoading = true;
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          except: this.$route.query.id,
          // keyword: this.search,
          page: 1,
          limit: 40,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.empList1 = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getMngr() {
      this.appLoading = true;
      axios({
        url: "/managers/getlist",
        method: "GET",
        params: {
          employeeId: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.managerList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addAward() {
      var data = {};
      data["year"] = this.Ayear;
      data["awardId"] = this.award;
      data["employeeId"] = this.$route.query.id;
      axios({
        url: "/employee/award/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.grade = null;
            this.addAwardDialog = false;
            this.AwardDialog = false;
            this.getMyAwards();
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editAward() {
      var data = {};
      data["year"] = this.curyear;
      data["awardId"] = this.curaward;
      data["id"] = this.curid;
      data["employeeId"] = this.$route.query.id;

      axios({
        url: "/employee/award/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.grade = null;
            this.editAwardDialog = false;
            this.AwardDialog = false;
            this.getMyAwards();
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getYear1() {
      this.appLoading = true;
      axios({
        url: "/yearwise/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.singleYear = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getAwardList() {
      this.appLoading = true;
      axios({
        url: "/award/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.AwardList = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validateInput() {
      if (!this.password) {
        this.msg = "Please Provide New Password";
        this.showSnackBar = true;
        return;
      } else if (this.password != this.confirmpassword) {
        this.msg = " Password do not match!";
        this.showSnackBar = true;
        return;
      } else {
        this.changePassword();
      }
    },
    changePassword() {
      var userData = {};
      userData["password"] = this.password;
      userData["employeeId"] = this.$route.query.id;
      axios({
        method: "post",
        url: "/admin/change/employee/password",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.changePasswordDialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.table {
  background-color: red;
}
</style>